<template>
  <div class="e-content">
    <div id="Pagination">
      <h1>Pagination</h1>
      <p>페이지네이션은 Block List, Extrinsic List, Token List 이 세 페이지에 적용되었습니다. 세 페이지 모두 작동 방식은 같습니다. 페이지네이션 구현을 위해 부트스트랩 Vue를 사용했습니다.
      </p>

      <b-card title="사용 방법">
        <b-card-text> 📚 참고:
          <b-link
              href="https://bootstrap-vue.org/docs/components/pagination#pagination"
              target="_blank"
          >
            Bootstrap-v Pagination
          </b-link>
        </b-card-text>
        <b-card-text>
          부트스트랩의 b-pagination은 페이징을 도와주는 컴포넌트입니다. 현재 페이지(currentPage), 한 페이지에 그려질 데이터의 양(perPage), 페이지 수(rows) 등을 자동으로 데이터바인딩 해줍니다.
        </b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codeHowToPagination }}</code></pre>
        </div>
      </b-card>
      <b-card>
        <b-card-text>Tokens</b-card-text>
        <b-card-text>Tokens API는 모두 POST 방식으로 아래와 같이 네 가지 타입을 매개변수로 사용합니다.</b-card-text>
        <b-card-text>'start'는 한 페이지의 시작(배열의 첫번째)를 의미합니다. 'limit'은 한 페이지 당 그려질 데이터를 의미합니다. searchType은 검색할 타입, searchText는 검색 값을 의미합니다.</b-card-text>
        <b-card-text>
          <ul>
            <li>start</li>
            <li>limit</li>
            <li>searchType</li>
            <li>searchText</li>
          </ul>
        </b-card-text>
        <br />
        <b-card-text>1 페이지는 0~9, 2 페이지는 10~19, 3 페이지는 20~29 ...와 같이 진행됩니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codePagingStart
            }}</code></pre>
        </div>
        <b-card-text>한 페이지당 10개의 데이터를 가져옵니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codePagingLimit
            }}</code></pre>
        </div>
        <b-card-text>searchType 매개변수를 '앨범'으로 지정합니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codePagingType
            }}</code></pre>
        </div>
        <b-card-text>검색한 값이 매개 변수에 들어갈 수 있도록 지정합니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codePagingInput
            }}</code></pre>
        </div>
        <b-card-text>전체 데이터 수를 가진 API를 가져옵니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codeGetPagingToken
            }}</code></pre>
        </div>

        <b-card-text>실행되는 순서</b-card-text>
        <b-card-text>
          <ul>
            <li>처음 렌더링 시 clickPage(), token() 함수 실행</li>
            <li>watch에 의해 currentPage 값 변경 감지. 부트스트랩에서 자동으로 currentPage 값 계산</li>
            <li>rows는 total count를 입력하면 b-pagination에서 자동으로 반올림해줍니다.</li>
          </ul>
        </b-card-text>
        <b-card-text>Axios를 사용해 데이터를 불러올 때 배열에 바로 담아서 보여주면, 페이지네이션 기능이 제대로 작동하지 않습니다. 이렇게 사용할 시 새로운 데이터가 배열에 계속 쌓여 페이지네이션을 클릭할 때마다 데이터가 늘어납니다. 함수 안에 변수(temp)를 하나 지정해놓고, 데이터를 temp에 담은 후에 스크립트 내 데이터에 지정해둔 배열 변수에 다시 담아 페이지네이션을 클릭할 때마다 배열에 새로운 데이터가 보여지게 합니다.</b-card-text>
        <b-button
            class="btn btn-warning"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="visible = !visible"
        >
          전체 코드
        </b-button>
        <b-collapse id="collapse-1" v-model="visible" class="mt-2">
          <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                {{ codePagingAll }}
                </code>
              </pre>
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg, BButton, BCollapse, BLink} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {codeHowToPagination, codePagingStart, codePagingLimit, codePagingType, codePagingInput, codeGetPagingToken, codePagingAll} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    BLink,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Pagination'],
      visible: false,
      codeHowToPagination,
      codePagingStart,
      codePagingLimit,
      codePagingType,
      codePagingInput,
      codeGetPagingToken,
      codePagingAll
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

